@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: 'Public Sans' !important;
}

.css-i4bv87-MuiSvgIcon-root,
.css-vubbuv {
  font-size: 1rem !important;
}

.css-gbjsxz-MuiBackdrop-root-MuiDialog-backdrop,
.css-1i2ih6x {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

/* .MuiBox-root, .css-9a7vyu {
    background-color: #fff !important;  
} */

.css-19nrip4-MuiFormHelperText-root {
  font-weight: 400 !important;
  color: #ff002f !important;
}

.css-1pecim6-MuiPaper-root-MuiDialog-paper {
  box-shadow: none !important;
}

.css-hsr1vq-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  @apply !text-SkyBlue
  /* color: #0079ff !important; */
  ;
}

/* .css-1c1tukf-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
    height: auto !important;
} */

/* .MuiInputBase-input{
    height: 30px !important;
    border: 1px solid #2e2e2e !important;
    border-radius: 6px !important;
    padding: 8px !important;
} */

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiButtonBase-root-MuiTab-root.Mui-selected {
  border-radius: 10px !important;
  color: #ffffff !important;
  /* background-image: linear-gradient(90deg, #4d22ae, #974ec0) !important; */
}

/* File Upload with drag */
#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.imageiconBox {
  display: none !important;
}

.hoverImg:hover .imageiconBox,
.imageiconBox:hover {
  display: block !important;
  width: 100%;
  height: 100%;
  background: #b3b2b287;
  border-radius: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.ReactCrop__image,
.ReactCrop--invisible-crop,
.ReactCrop {
  display: flex;
}

.css-ma5kyv-MuiDataGrid-root .MuiDataGrid-row:hover {
  background-color: #9d71b30d !important;
}

.css-ma5kyv-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-ma5kyv-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: #ffffff !important;
}

.css-ma5kyv-MuiDataGrid-root .MuiDataGrid-columnHeader--moving {
  background-color: #ffffff !important;
}

.css-yu0g60-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}

.css-10aktab-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-8csimg.Mui-selected {
  @apply !text-SkyBlue
}

.css-16bf516-MuiTabs-indicator, .css-1ubixi6 {
  @apply !bg-transparent !shadow-none !border-t-0 !border-l-0 !border-r-0 !border-b-2 !border-solid !border-SkyBlue !rounded-none
}

.css-1k9re4l-MuiDataGrid-root .MuiDataGrid-cell:focus, .css-1k9re4l-MuiDataGrid-root .MuiDataGrid-cell:focus-within,
.css-1k9re4l-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-1k9re4l-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-pz6njf .MuiDataGrid-cell:focus-within, .css-pz6njf .MuiDataGrid-cell:focus, .css-pz6njf .MuiDataGrid-columnHeader:focus, .css-pz6njf .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  @apply bg-SkyBlue rounded-borderRadius10;
}

.MuiInputBase-input:focus,
.css-4hy2u4-MuiInputBase-input-MuiOutlinedInput-input:focus,
.css-h5wa9b-MuiInputBase-root-MuiOutlinedInput-root:focus,
.mui-focused:focus {
  -webkit-tap-highlight-color: transparent !important;
  outline: 0 !important;
  border-color: transparent !important;
}

.css-pb771q-MuiInputBase-input-MuiOutlinedInput-input:disabled {
  background-color: #eae9e9 !important;
  border-radius: 10px;
  cursor: no-drop;
}

.css-pb771q-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1 !important;
  -webkit-text-fill-color: #000000ab !important
}

.edit-Icon:hover {
  color: #fff;
  background: #00000093;
}

.edit-Icon {
  position: absolute;
  width: 92%;
  height: 92%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  top: 4%;
  left: 4%;
  color: transparent;
  background: transparent;
  z-index: 999;
}


.ReactCrop__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ReactCrop {
  width: 100%;
  height: 100%;
}

.react-crop-custom>div:first-child {
  /* Your styles for the first child div here */
  width: 100%;
  height: 100%;
}

/* .ReactCrop div {
  width: 100%;
  height: 100%;
} */